import "core-js/modules/es.function.name.js";
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4e8f51fc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "slot-container-outer"
};
var _hoisted_2 = {
  class: "slot-container overlayed-container"
};
var _hoisted_3 = {
  key: 1,
  class: "image missing"
};
var _hoisted_4 = {
  class: "flex-col"
};
var _hoisted_5 = {
  key: 0,
  class: "tag_name"
};
var _hoisted_6 = {
  class: "flex-col"
};
var _hoisted_7 = {
  notranslate: ""
};
var _hoisted_8 = ["innerHTML"];
import { ref, computed } from 'vue';
import { PictureFilled } from '@element-plus/icons-vue';
import ImageWithAlt from '@/components/ImageWithAlt';
import { formatPostDate } from '@/helpers';
export default {
  props: {
    post: {
      required: true,
      type: Object
    },
    showDate: {
      required: false,
      type: Boolean,
      default: false
    },
    showExcerpt: {
      required: false,
      type: Boolean,
      default: false
    },
    pathName: {
      type: String,
      required: false,
      default: "CommunityPost"
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true
    },
    centered: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    var title = ref(null);
    var excerpt = ref(null);
    var trimmedExcerpt = ref(props.post.post_excerpt);
    var tagName = computed(function () {
      return props.post.tags ? props.post.tags[0].name : null;
    });
    var formattedDate = computed(function () {
      return formatPostDate(props.post.post_date);
    });
    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      var _component_el_container = _resolveComponent("el-container");

      var _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createBlock(_component_router_link, {
        to: {
          name: __props.pathName,
          params: {
            slug: __props.post.post_name
          }
        },
        class: "card-link"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_container, {
            direction: "vertical",
            class: "card fill-container"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, function () {
                return [Array.isArray(__props.post.image) && __props.post.image[0] ? (_openBlock(), _createBlock(_unref(ImageWithAlt), {
                  key: 0,
                  class: "fill-container",
                  loading: __props.lazy ? 'lazy' : 'eager',
                  lazy: __props.lazy,
                  fit: "contain",
                  src: __props.post.image[0]
                }, null, 8, ["loading", "lazy", "src"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_icon, {
                  class: "pic-icon overlayed-container flex-centered"
                }, {
                  default: _withCtx(function () {
                    return [_createVNode(_unref(PictureFilled))];
                  }),
                  _: 1
                })]))];
              })])]), __props.showDate ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                align: "middle",
                justify: "space-between",
                class: "meta-info"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("div", _hoisted_4, [Array.isArray(__props.post.tags) && __props.post.tags[0] ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", null, _toDisplayString(_unref(tagName)), 1)])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString(_unref(formattedDate)), 1)])];
                }),
                _: 1
              })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
                class: "body fill-container",
                style: _normalizeStyle({
                  'padding-top': !__props.showDate ? '15px' : '0px'
                })
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_col, {
                    style: _normalizeStyle([{
                      "display": "flex",
                      "flex-direction": "column",
                      "height": "100%"
                    }, {
                      'justify-content': __props.showExcerpt ? 'space-evenly' : 'flex-start'
                    }])
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("h3", {
                        ref_key: "title",
                        ref: title,
                        class: "title line-clamped",
                        innerHTML: __props.post.post_title,
                        style: _normalizeStyle({
                          'text-align': __props.centered ? 'center' : 'unset'
                        })
                      }, null, 12, _hoisted_8), __props.showExcerpt ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        ref_key: "excerpt",
                        ref: excerpt,
                        class: "excerpt line-clamped"
                      }, _toDisplayString(trimmedExcerpt.value), 513)) : _createCommentVNode("", true)];
                    }),
                    _: 1
                  }, 8, ["style"])];
                }),
                _: 1
              }, 8, ["style"])];
            }),
            _: 3
          })];
        }),
        _: 3
      }, 8, ["to"]);
    };
  }
};